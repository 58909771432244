import logo from './img/logo3.svg';
import svg_streaming from './img/streaming.svg';
import svg_brain from './img/brain.svg';
import svg_target from './img/target.svg';
import img_lamp from './img/istockphoto-534595074-612x612.jpg';
import img_lamp2 from './img/iStock-531775809-opt.jpg';
import img_desk from './img/iStock-526307221-opt.jpg';
import './App.scss';
import { Parallax } from 'react-parallax';

import 'simplebar';
import 'simplebar/dist/simplebar.css';
import { useLayoutEffect, useRef, useState } from 'react';

function App() {
  return (
    <div>
      <Parallax bgImage={img_lamp2} bgImageAlt="the cat" strength={300} >
        <div className="h-screen w-screen flex">
          <div className={`absolute w-screen z-10`}>
            <img src={logo} className="p-8 w-screen md:w-auto sm:p-12 md:p-16" />
          </div>
          <h1 className="text-white font-black text-6xl m-auto text-center">Solving complex problems</h1>
        </div>
      </Parallax>
      <div className="grid grid-flow-row md:grid-flow-col md:grid-cols-3 grid-cols-1 gap-8 p-4 m-16 text-center" style={{ gridTemplateRows: "repeat(2, minmax(0, auto))" }}>
        <img src={svg_brain} className="h-16 w-16 mt-8 md:mt-0 mx-auto" />
        <h1 className="text-2xl h-auto mb-6 md:mb-0">AI</h1>
        {/* <div className="text-lg">
          <p className="mb-4">Explore</p>
          <p className="mb-4">Train</p>
          <p className="mb-4">Deploy</p>
        </div> */}
        <img src={svg_streaming} className="h-16 w-16 mt-8 md:mt-0 mx-auto" />
        <h1 className="text-2xl h-auto mb-6 md:mb-0">Streaming Data</h1>
        {/* <div className="text-lg">
          <p className="mb-4">Architect</p>
          <p className="mb-4">Build</p>
          <p className="mb-4">Teach</p>
        </div> */}
        <img src={svg_target} className="h-16 w-16 mt-8 md:mt-0 mx-auto" />
        <h1 className="text-2xl h-auto mb-6 md:mb-0">Product development</h1>
        {/* <div className="text-lg">
          <p className="mb-4">Define</p>
          <p className="mb-4">Design</p>
          <p className="mb-4">Develop</p>
        </div> */}
      </div>
      <Parallax bgImage={img_desk} bgImageAlt="the cat" strength={200} >
        <div className="h-96">
        </div>
      </Parallax>

      <div className="bg-gray-700 p-16 pb-4 text-white">
        <h2 className="text-xl mb-4">Applied Science and Fiction Limited</h2>
        <p>96/5 Spottiswoode Street, Edinburgh, EH9 1BY, United Kingdom</p>
        <br />
        <p>Phone <b>+44 791 951 89 87</b></p>
        <p>Email <b>contact@appscifi.com</b></p>
        <br />
        <br />
        <p className="text-sm text-gray-400">© Applied Science and Fiction Limited • Company registration: 07794500 • VAT: GB122145069</p>
      </div>
    </div>

  );
}

export default App;
